#earth {
    position: fixed;
    width: 50vw;
    height: 50vw;
    margin: auto;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5) url(world.png);
    background-size: cover;
    box-shadow: inset 0px -20px 50px 10px rgba(255, 255, 255, 0.5), 0px 0px 30px 6px rgba(226, 226, 226, 0.44);
    transform-style: preserve-3d;
    animation: rotate 15s linear infinite;
}

@keyframes rotate {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 530px 0;
    }
}

@media screen and (max-width: 768px) {
    #earth {
        width: 80vw;
        height: 80vw;
    }
}
