@keyframes parallaxstars {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.event-stars-background{
    background-size: 400%;
    animation: parallaxstars 60s infinite linear;
} 
