@font-face {
    font-family: robo;
    src: url(robo.ttf);
}

.scontainer {
    width: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: radial-gradient(ellipse at bottom, #0076ff 0%, #090a0f 100%);
    color: white;
}

.sblock {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
}

.slogo {
    width: 190px;
    object-fit: contain;
    margin-bottom: 30px;
    animation: fadeIn 1s 1.5s backwards;
    /* flex-basis: 191px; */
}

.sh1 {
    font-family: robo;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    margin: 60px;
    animation: up 1s ;
}

.glass-sponsor {
    width: 50%;
    padding: 10px;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeglass 1s forwards;
    background: rgba( 100,100,200, 0.1 );
    border-radius: 20px;
    box-shadow: inset 0px 0px 32px 0 rgba( 255, 255, 255, 0.18 );
}

@keyframes up {
    from {
        transform: translateY(300px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes fadeIn {

    /* Define keyframe styles */
    from {
        opacity: 0;
        transform: translateY(-100%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeglass {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .sh1 {
        font-size: 1.8em;
    }

    .glass {
        width: 80%;
    }
    .glass-sponsor {
        width: 80%;
        height: 80%;
    }
    .sblock{
        gap:5px;
    }
    .slogo{
        width: 120px;
    }
}