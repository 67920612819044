@font-face {
  font-family: nasa;
  src: url(nasa.otf);
}

.Heading-work {
  position: absolute;
  top: 17px;
  left: 120px;
  width: calc(100% - 240px);
  background: transparent;
  color: #ffaf00;
  text-shadow: 0 0 12px #ffaf00;
  border: none;
  text-align: center;
  letter-spacing: 0.1em;
}

.SpBackground-work {
  height: 230px;
  width: 250px;
  position: absolute;
  top: 55%;
  z-index: -1;
  transform: translateY(-50%);
  left: 5%;
  /* width: 358px; */
  /* margin-left: -32em; */
  /* margin-top: 4em;  */
}

.CardBack-work {
  position: fixed;
  /* width :50%; */
  height: 80%;
  z-index: -9;
  rotate: 180deg;
  filter: hue-rotate(86deg);
}

.Text-container-work p {
  letter-spacing: 2px;
}

.popup-image-work {
  position: absolute;
  border-radius: 25px;
  box-shadow: 0 0 10px #ffaf00;
  width: 250px;
  top: 25%;
  left: 5%;
  /* filter: brightness(0.9) contrast(1.5); */
  /* animation: popup 1s 1.5s forwards; */
}

/* @keyframes popup {
  from {
    height: 400px;
    width: 345px;
    top: 35%;
  left: 32%;
  }
  to {
    width: 345px;
    height: 400px;
    top: 35%;
  left: 31.5%;
  }
} */

/* =======================================
CONTENT FONTS AND SIZE
======================================= */

@font-face {
  font-family: 'Flare-Regular';
  src: url("../Sponsor/Flare-Regular.otf") format('truetype');
}

@font-face {
  font-family: 'project-space';
  src: url("../Sponsor/project-space-font/Project\ Space.otf") format('opentype');
}

@font-face {
  font-family: 'primal';
  src: url("../Sponsor/elements-primal-G9W39N-2017-04-16/Primal.otf") format('opentype');
}

@import url('https://fonts.cdnfonts.com/css/do-futuristic');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.violet {
  color: #ff0202;
}
.work-no{
  scroll-snap-type: both mandatory;
}
.FuturName-work {
  font-family: 'nasa', sans-serif;
  font-weight: bold;
  font-size: 15px;
  /* text-align: justify; */
  /* margin-right: 2rem; */
}

.posValue {
  font-family: 'nasa', sans-serif;
  margin-left: 5.6em;
  font-size: 10px;
  color: #ff0202;
}

.tagValue-work {
  font-family: 'nasa', sans-serif;
  display: block;
  font-size: 13px;
  color: #ff0202;
  margin-top: 3px;
  margin-bottom: 20px;
}

.Text-container-work {
  color: #ffaf00;
  position: absolute;
  width: 53%;
  right: 3%;
  top: 20%;
  /* margin-left: 30rem ; */
  /* margin-right: 1rem; */
  text-shadow: 0px 0px 6px rgb(255 255 255 / 70%);
}


.SpHeading-work {
  font-family: 'Flare-Regular';
  font-size: 35px;
  letter-spacing: 0.8rem;
  font-weight: 800;
  margin-bottom: 40px;
  display: flex;
  margin-left: -1rem;
  text-shadow: 0px 2px 18px rgb(255 255 255 / 70%);
}

/* =======================================
CONTENT FONTS AND SIZE
======================================= */


.hero-work {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-position: center
}


@keyframes animateGrain {

  /* 0%, 100% { transform:translate(0, 0) } */
  10% {
    transform: translate(-5%, -10%)
  }

  20% {
    transform: translate(-15%, -20%)
  }

  30% {
    transform: translate(-5%, -10%)
  }

  40% {
    transform: translate(-15%, -20%)
  }

  50% {
    transform: translate(-5%, -10%)
  }

  60% {
    transform: translate(-15%, -20%)
  }

  70% {
    transform: translate(-5%, -10%)
  }

  80% {
    transform: translate(-15%, -20%)
  }

  90% {
    transform: translate(-5%, -10%)
  }

  100% {
    transform: translate(-15%, -20%)
  }

}


.hero-work:after {
  content: "";
  background-image: url(".././media/Noise\ Texture.png");
  height: 110%;
  width: 110%;
  position: fixed;
  opacity: 0.17;
  animation: animateGrain 8s steps(5) infinite;
}

@keyframes animateGrain {

  0%,
  100% {
    transform: translate(0, 0)
  }

  10% {
    transform: translate(-5%, -10%)
  }

  20% {
    transform: translate(-15%, -20%)
  }

  30% {
    transform: translate(-5%, -10%)
  }

  40% {
    transform: translate(-15%, -20%)
  }

  50% {
    transform: translate(-5%, -10%)
  }

  60% {
    transform: translate(-15%, -20%)
  }

  70% {
    transform: translate(-5%, -10%)
  }

  80% {
    transform: translate(-15%, -20%)
  }

  90% {
    transform: translate(-5%, -10%)
  }

  100% {
    transform: translate(-15%, -20%)
  }

}

@keyframes bordergradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}




.glow-container-work {
  position: absolute;
  inset: 0;
  display: grid;
  z-index: 2;
  pointer-events: none;
  background-color: transparent;
  --glow: drop-shadow(1px 0px 1px #222121);
  filter: var(--glow) brightness(1) drop-shadow(0 0 10px #f0fa);
}


.augs-work {
  --aug-rect-l1: initial;
  --aug-l1-width: 110px;
  --aug-l1-height: 4px;
  --aug-l-center: 57px;

  --aug-rect-r1: initial;
  --aug-r1-width: (100% - 125px - 50px);
  --aug-r1-height: 4px;
  --aug-r-center: 57px;

  --aug-clip-tr1: initial;
  --aug-tr1-alt-join-out: initial;
  --aug-tr1: 17px;
  --aug-clip-tr2: initial;
  --aug-tr2: 17px;
  --aug-tr-extend1: 50px;

  --aug-round-tl1: initial;
  --aug-tl1: 8px;
  --aug-round-br1: initial;
  --aug-br1: 8px;
  margin: auto;
  width: calc(100% - var(--glow-margin) * 2);
  height: calc(100% - var(--glow-margin) * 2);
}

.glow-container-work .augs-work {

  --aug-border: initial;
  --aug-border-all: 2px;
  --aug-border-bg: linear-gradient(to bottom right, #ff00ff, #ff7700);
}

.work-no-container {
  display: flex;
  height: 32px;
  overflow: hidden;
}

.work-no-sub {
  display: flex;
  flex-direction: column;
}

.work-no-container,
.work-no-sub,
.work-no,
.worker-name-container,
.worker-name-sub,
.worker-name-text,
.about-work-container,
.about-work-sub,
.about-work-text {
  transition: transform 0.5s ease-in;
}

.worker-name-container {
  display: flex;
  height: 16px;
  overflow: hidden;
}

.worker-name-sub {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.about-work-container {
  display: flex;
  height: 52px;
  overflow: hidden;
}

.about-work-sub {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


@media screen and (max-width: 780px) {
  .work-no-container{
    height: 24px;
  }
  .about-work-container{
    height: 54px;
  }
  .Heading-work {
    position: absolute;
    top: 17px;
    /* left: 50%; */
    width: calc(100% - 240px);
    background: transparent;
    /* color: #00ffe4;
    text-shadow: 0 0 12px #00ffe4; */
    border: none;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .hero-work:after {
    content: "";
    background-image: url(".././media/Noise\ Texture.png");
    height: 120%;
    width: 100%;
    position: fixed;
    opacity: 0.15;
    animation: animateGrain 20s steps(10) infinite;
  }

  .popup-image-work {
    /* width: 39%;
    height: 18%;
    left: 31%;
    top: 27%; */
    top: 15%;
    width: 150px;
    left: 50%;
    transform: translateX(-50%);
  }

  .SpBackground-work {
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 130px;
    /* width: 10rem;
    height: 10rem;
    margin-right: -31rem;
    margin-top: -16.5rem; */
  }

  .Text-container-work {
    width: 100%;
    top: 51%;
    right: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Text-container-work-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .FuturName-work {
    font-family: "nasa";
    font-size: 12px;
    font-weight: bold;
    margin-left: 35px;
    margin-bottom: 0;
    margin-right: 25px;
    /* text-align: justify; */
  }

  .posValue {
    font-family: "nasa";
    /* margin-left: 11em; */
    font-size: 8px;
    /* color: #00ffe4; */
  }

  .tagValue-work {
    font-family: "nasa";
    /* display: none; */
    /* text-align: justify; */
    font-size: 10.5px;
    margin-bottom: 0;
    /* color: #00ffe4; */
  }

  .SpHeading-work {
    font-family: 'Flare-Regular';
    font-size: 25px;
    align-self: center;
    letter-spacing: 0.8rem;
    font-weight: 700;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .hero-work {
    width: 120%;
  }

}



/* .bg {
  background: repeating-linear-gradient(to top,
      rgba(255, 255, 255, 0.03) 0px 2px,
      transparent 2px 4px),
    linear-gradient(to bottom, #93f3f344 75%, #10a3a38f);
}

.bg::after {
  content: '';
  height: 50%;
  width: 100%;
  display: block;
  background-image: linear-gradient(90deg, rgba(0, 255, 228, 1) 1px, rgba(0, 0, 0, 0) 1px), linear-gradient(0deg, rgba(0, 255, 228, 1) 1px, rgba(0, 0, 0, 0) 1px);
  background-position: bottom;
  background-repeat: repeat;
  background-size: 20px 20px;
  left: -25px;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  transform: perspective(100px) rotateX(60deg);
  z-index: 0;
} */


/* section.augs {
  position: absolute;
  inset: 0;
  margin: auto;
  --aug-inlay: initial;
  --aug-inlay-all: 2px;
  --aug-inlay-opacity: 0.25;
}

section.augs::before {
  filter: brightness(0.2) blur(10px);
} */

/* body {
  display: grid;
  min-height: 100vh;
  width: 100vw;
  --c: rgb(0 0 0 / 0.95);
  --bgb: linear-gradient(var(--c), var(--c));
  --bg: repeating-conic-gradient(from 7.5deg at center center,
      hsl(200, 100%, 0%) 0deg 15deg,
      hsl(200, 100%, 60%) 10deg 30deg);
  background-color: #001716;
} */

/* .code-container {
  overflow: hidden;
  margin: auto;
  width: 50vw;
  height: 80vh;
  min-width: 420px;
  min-height: 255px;
  position: relative;
  --glow-margin: 70px;
  filter: drop-shadow(0 0 75px rgb(128 0 255 / 0.25));
}

.code-container::before {
  content: attr(content);
  position: absolute;
  bottom: 90px;
  right: 90px;
  z-index: 10;
  color: #00ffe4;
  text-shadow: 0 0 12px #00ffe4;
  letter-spacing: 0.1em;
}

.code-container::after {
  content: "";
  position: absolute;
  top: 125px;
  left: var(--glow-margin);
  right: var(--glow-margin);
  height: 4px;
  display: block;
  z-index: 4;
  opacity: 0.5;
} */

/* .dots {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 110px;
    height: 50px;
    --red: #fc199a;
    --yellow: #ffcc00;
    --green: #61e2ff;
    --close: radial-gradient(circle, var(--red), var(--red) 7px, transparent 8px);
    --min: radial-gradient(circle, var(--yellow), var(--yellow) 7px, transparent 8px);
    --max: radial-gradient(circle, var(--green), var(--green) 7px, transparent 8px);
    background: var(--close) -28px no-repeat,
                var(--min) -2px no-repeat,
                var(--max) 24px no-repeat;
    filter: brightness(0.5);
    opacity: 0.75;
    cursor: pointer;
    border: none;
  } */
/* 
.CodeMirror-gutters {
  background: transparent;
  border: none;
  margin-right: 15px;
}

.CodeMirror {
  height: 100%;
  background: transparent;
}

.CodeMirror-linenumber {
  padding-right: 1em;
}

.CodeMirror-overlayscroll-horizontal div,
.CodeMirror-overlayscroll-vertical div {
  background: rgba(153, 99, 255, 0.5);
}

.CodeMirror-scrollbar-filler {
  display: none !important;
}



.highcontrast-dark .cm-header {
  font-weight: bold
}

.highcontrast-dark .cm-strong {
  font-weight: bold
}

.highcontrast-dark .cm-em {
  font-style: italic
}

.highcontrast-dark .CodeMirror-cursor {
  border-left-color: white !important
}

.highcontrast-dark .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.05)
}

.highcontrast-dark .CodeMirror-focused .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.1)
}

.highcontrast-dark .CodeMirror-matchingbracket {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5)
}

.highcontrast-dark .CodeMirror-matchingtag {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3)
}

.highcontrast-dark .powers {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05)
}

.highcontrast-dark .cm-searching {
  background: black;
  outline: 2px solid rgba(255, 255, 255, 0.25)
}

.highcontrast-dark .CodeMirror-hints,
.highcontrast-dark .emmet-abbreviation-preview {
  border: 1px solid #5a5f73;
  background: #1e1f26
}

.highcontrast-dark .CodeMirror-hint {
  color: white
}

.highcontrast-dark li.CodeMirror-hint-active {
  background: #c7c9d3;
  color: black
}

.highcontrast-dark .project-editor-warning {
  background: rgba(255, 255, 255, 0.1)
}

.highcontrast-dark .cm-atom {
  color: #00ffe4;
}

.highcontrast-dark .box-html .cm-atom {
  color: #ff3c41
}

.highcontrast-dark .cm-def,
.highcontrast-dark .cm-variable-2,
.highcontrast-dark .cm-variable,
.highcontrast-dark .box-js .cm-variable+.cm-property {
  color: #61e2ff;
  text-shadow: 0 0 2px #001716, 0 0 5px #03edf933, 0 0 10px #ffff6633;
}

.highcontrast-dark .cm-header {
  color: #ff3c41
}

.highcontrast-dark .cm-builtin {
  color: #00ffe4;
}

.highcontrast-dark .cm-number {
  color: #00ffe4;
}


.highcontrast-dark .cm-operator,
.highcontrast-dark .cm-variable-3,
.highcontrast-dark .cm-attribute,
.highcontrast-dark .cm-property,
.highcontrast-dark .cm-keyword,
.highcontrast-dark .presentation>.cm-def,
.highcontrast-dark .cm-qualifier {
  color: #00ffe4;
  text-shadow: 0 0 2px #393a33, 0 0 35px #ffffff44, 0 0 10px #00ffe4, 0 0 2px #00ffe4;
}

.highcontrast-dark .cm-operator,
.highcontrast-dark .cm-variable-3,
.highcontrast-dark .cm-attribute,
.highcontrast-dark .cm-property {
  font-style: italic;
}

.highcontrast-dark .cm-meta,
.highcontrast-dark .cm-meta+.cm-property,
.highcontrast-dark .cm-string,
.highcontrast-dark .cm-string-2 {
  color: #00ffe4;
  text-shadow: none;
}

.highcontrast-dark .cm-tag,
.highcontrast-dark .cm-callee,
.highcontrast-dark .box-css .cm-tag,
.highcontrast-dark .cm-tag.cm-bracket,
.highcontrast-dark .box-js .cm-property {
  color: #ffcc00;
  text-shadow: 0 0 2px #100c0f, 0 0 3px #ffaa0099, 0 0 5px #ffaa0099, 0 0 10px #ffaa0099;
  font-style: italic;
}

.highcontrast-dark .cm-comment {
  font-style: italic;
  color: #00ffe4;
  text-shadow: 0 0 2px #001716, 0 0 5px #03edf933, 0 0 10px #ffff6633;
}

body.editor.highcontrast-dark {
  background: #241b2f;
}

.highcontrast-dark .box.box.box,
.editor.highcontrast-dark .top-boxes,
.highcontrast-dark .CodeMirror-gutter-wrapper,
body.project.highcontrast-dark .editor-pane,
body.project.highcontrast-dark .editor {
  background: none;
}

.editor.highcontrast-dark .top-boxes {
  --swxfm__c: rgba(0, 255, 228, 1);
  background: repeating-linear-gradient(to top,
      rgba(255, 255, 255, 0.03) 0px 2px,
      transparent 2px 4px),
    linear-gradient(to bottom, #00ffe4 75%, #00ffe4);
}

.editor.highcontrast-dark .top-boxes:after {
  content: '';
  height: 300px;
  width: 100%;
  display: block;
  background-image: linear-gradient(90deg, rgba(0, 255, 228, 1) 1px, rgba(0, 0, 0, 0) 1px), linear-gradient(0deg, rgba(0, 255, 228, 1) 1px, rgba(0, 0, 0, 0) 1px);
  background-position: bottom;
  background-repeat: repeat;
  background-size: 20px 20px;
  left: -25px;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  transform: perspective(100px) rotateX(60deg);
  z-index: 0;
}

.highcontrast-dark .box.box.box pre,
.editor.highcontrast-dark .top-boxes pre,
.highcontrast-dark .CodeMirror-gutter-wrapper pre,
body.project.highcontrast-dark .editor-pane pre,
body.project.highcontrast-dark .editor pre {
  color: #b5b4b6;
}

.highcontrast-dark .CodeMirror-guttermarker-subtle,
.highcontrast-dark .CodeMirror-linenumber {
  color: #00ffe4;
  text-shadow: 0 0 2px #393a33, 0 0 35px #ffffff44, 0 0 10px #00ffe4, 0 0 2px #00ffe4;
} */