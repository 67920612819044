.form-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  top: 70px;
  position: relative;
  text-transform: uppercase;
  color: #ddd;
  font-family: nasa;
  margin-bottom: 200px;
}

.registration-h1 {
  margin-top: 50px;
  font-size: 2.5em;
  color: #ddd;
  text-transform: uppercase;
  letter-spacing: 5px;
  /* font-family: nasa; */
  text-shadow: 0 2px 4px #fffc;
}

.form-container {
  padding-bottom: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8), inset 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40%;
  /* height: 100; */
  border-radius: 10px;
  background-color: #222222;
}

/* radio button */
.container-form-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.container-form-radio p {
  color: #ddd;
  text-transform: uppercase;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ul li {
  transform: scale(0.7) translateY(40px);
  color: #AAAAAA;
  display: block;
  position: relative;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  /* float: left; */
  margin-top: -60px;
  width: 100%;
  height: 100px;
  /* border-bottom: 1px solid #333; */
}

ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label {
  color: #FFFFFF;
}

ul li .check {
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  left: 20px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

ul li:hover .check {
  border: 5px solid #FFFFFF;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
  left: 5px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check {
  border: 5px solid #0DFF92;
}

input[type=radio]:checked~.check::before {
  background: #0DFF92;
}

input[type=radio]:checked~label {
  color: #0DFF92;
}

/* Balloon */
.row {
  position: relative;
  margin: 20px 0 20px;
  padding: 0;
  list-style: none;
}

.balloon {
  display: inline-block;
  width: 250px;
  padding: 10px 0 10px 15px;
  font-family: nasa;
  text-transform: uppercase;
  font-weight: 400;
  color: #377D6A;
  background: #efefef;
  border: 0;
  border-radius: 3px;
  outline: 0;
  text-indent: 60px;
  transition: all .3s ease-in-out;
}

.balloon::-webkit-input-placeholder {
  color: #efefef;
  text-indent: 0;
  font-weight: 300;
}

.balloon+label {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
  /* bottom: 8px; */
  padding: 5px 15px;
  color: #032429;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(19, 74, 70, 0);
  transition: all .3s ease-in-out;
  border-radius: 3px;
  background: rgba(122, 184, 147, 0);
}

.balloon+label:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -3px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid rgba(122, 184, 147, 0);
  transition: all .3s ease-in-out;
}

.balloon:focus,
.balloon:active {
  color: #377D6A;
  text-indent: 0;
  background: #fff;
}

.balloon:focus::-webkit-input-placeholder,
.balloon:active::-webkit-input-placeholder {
  color: #aaa;
}

.balloon:focus+label,
.balloon:active+label {
  color: #fff;
  text-shadow: 0 1px 0 rgba(19, 74, 70, 0.4);
  background: #7ab893;
  transform: translateY(-40px);
}

.balloon:focus+label:after,
.balloon:active+label:after {
  border-top: 4px solid #7ab893;
}

/* button */
.btnr {
  color: white;
  /* font-size: 20px; */
  font-weight: 700;
  text-transform: uppercase;
  font-family: nasa;
  margin-top:20px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 7px;
  border: none;
  background: linear-gradient(145deg, #242424, #1f1f1f);
  box-shadow: 8px 8px 16px #0e0e0e,
    -8px -8px 16px #363636;
}

.btnr:hover {
  background: linear-gradient(145deg, #1f1f1f, #323232);
}

.btnr:active{
  box-shadow: inset 8px 8px 16px #0e0e0e,
    inset -8px -8px 16px #363636;
}

.payment-desc{
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.totalrs{
  margin-bottom: 0;
}
.inclrs{
  font-size: 10px;
}

@media screen and (max-width: 600px) {
  .registration-h1{
    font-size: 2em;
    margin-left: 30px;
    margin-right: 30px;
  }
}
.terms{
  position: relative;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  text-decoration: none;
}
.terms-a{
  color: #fff;
  text-decoration: none;
}