body:has(.requires-no-scroll) {
    overflow: hidden;
}

.payment-main-container {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
}

.payment-container {
    background-color: #333;
    /* box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.5), inset 0 0 10px rgba(0, 0, 0, 0.2); */
    min-width: 35%;
    height: 50%;
    position: fixed;
    color: white;
    font-family: nasa;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:center; */
    z-index: 1000;
    transition: background-color 0.5s ease-in-out;
}


.payment-container:has(.payment-success) {
    background: linear-gradient(45deg, #2ba82b, #348b8b);
}
.payment-container:has(.payment-fail){
    background: linear-gradient(45deg, #a82b2b, #a1207d);
}
.payment-header {}

.payment-body {}

.payment-refresh {}

.payment-success {}

.payment-fail {}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinnerop {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.loading-spinner-container, .checkmark-container, .cross-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner-r {
    position: absolute;
    width: 50px;
    height: 50px;
    border-right: 5px solid #f3f3f3;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.loading-spinner-l {
    width: 40px;
    height: 40px;
    border-right: 4px solid #f3f3f3;
    border-radius: 50%;
    animation: spinnerop 1.5s linear infinite;
    z-index: 100;
}
.checkmark, .cross{
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .payment-container{
        padding: 20px;
    }
}