.background-loader {
    background: #202020;
    font-family: "Inconsolata", monospace;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100vh;
}

.loader-wrapper {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 400px;
    height: 400px;
}

.loader-outer {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 30px;
    border-radius: 20px;
    border-bottom: 1px solid #303030;
    background: #151515;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1;
}

.loader-outer .loader-inner {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: -100px;
    width: auto;
    height: 58px;
    color: crimson;
    text-align: center;
    text-shadow: 0 0 20px crimson;
    font-size: 60px;
    z-index: 2;
    animation: load 2s infinite linear;
}

.h1-loader {
    position: absolute;
    margin: auto;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85px;
    height: 40px;
    text-transform: uppercase;
    text-align: left;
    text-shadow: 0 0 20px crimson;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: lighter;
    color: crimson;
}

.h1-loader .span-loader {
    display: none;
}

.h1-loader::after {
    animation: txt 1s infinite;
    content: "";
}

@keyframes load {
    0% {
        left: -100px;
    }

    100% {
        left: 220px;
    }
}

@keyframes txt {
    0% {
        content: "LOADING";
    }

    35% {
        content: "LOADING.";
    }

    65% {
        content: "LOADING..";
    }

    100% {
        content: "LOADING...";
    }
}