@font-face {
    font-family: "Neuton";
    src: url(https://fonts.googleapis.com/css?family=Neuton);
}
@font-face {
    font-family: "Oswald";
    src: url(https://fonts.googleapis.com/css?family=Oswald);
}
@font-face {
    font-family: "jad";
    src: url(./jad.ttf);
}

/* body {
    font: 400 1em/1.5 "Neuton";
    background: #090d00;
    color: rgba(255, 255, 255, 0.25);
    text-align: center;
    margin: 0;
} */

.pwn-text-container {
    letter-spacing: 0.5em;
    display: inline-block;
    border: 4px double rgba(255, 255, 255, 0.25);
    border-width: 4px 0;
    padding: 10px 30px;
    position: fixed;
    bottom: 5%;
    left: 50%;
    /* width: 20%; */
    transform: translateX(-50%);
    opacity: 0;
    text-align: center;
    /* margin: 0 0 0 -20em; */
    animation: fadein 2s 1s forwards;
}

.pwn-text-container .pwn-text{
    font: 700 2.5em/1 "jad", sans-serif;
    letter-spacing: 0;
    padding: 0.25em 0 0.325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* Clip Background Image */
    background: url(fill.png) repeat-y;
    filter: sepia(100%) hue-rotate(140deg) contrast(4) saturate(200%);
    -webkit-background-clip: text;

    background-clip: text;
    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    animation: aitf 80s linear infinite;
    /* Activate hardware acceleration for smoother animations */
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}    

/* Animate Background Image */
@-webkit-keyframes aitf {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}
@keyframes aitf {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

@media screen and (max-width: 768px) {
    .pwn-text-container .pwn-text {
        font: 700 1.8em/1 "Oswald", sans-serif;
    }
}