.animation-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
}

.sphere {
  /* width: 90%; */
  height: 100%;
  overflow: hidden;
  /* margin-left: 90%;
  margin-top: 20%; */
  z-index: -1000;
}

.sphere-animation {
  position: fixed;
  height: 100%;
  transform: scale(1.2) translateX(22%);

}

.sphere path {
  fill: url(#sphereGradient);
  stroke-width: 1px;
  stroke: rgba(80, 80, 80, .35);
  backface-visibility: hidden;
}

@media screen and (max-width: 798px) {
  .sphere-animation {
    transform: scale(1) translateX(60%);
  }
}