.btn-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.btn {
  font-family: sans-serif;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  background-color: #1b8bf9;
  padding: 15px 50px;
  border-radius: 5rem;
  box-shadow: 1px 2.9px 16px rgba(27, 139, 249, 0.4);
  transition: 0.6s cubic-bezier(0.01, 1.69, 0.99, 0.94);
  z-index: 10;
}
.btn:hover {
  box-shadow: 3px 4.9px 16px rgba(27, 139, 249, 0.6);
  letter-spacing: 5px;
}
.btn--clicked {
  transition: 0.6s cubic-bezier(0.01, 1.69, 0.99, 0.94);
  padding: 15px 2px;
  width: 50px;
  color: transparent;
  z-index: -10;
}

.color {
  display: block;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.8s ease;
  border-radius: 50%;
  background-color: transparent;
}

.expanded {
  width: 300%;
  padding-bottom: 300%;
  height: auto;
}

.color--blue {
  background-color: #0e4378;
  transition-delay: 0.25s;
}

.color--cyan {
  background-color: cyan;
  transition-delay: 0.5s;
}

.color--yellow {
  background-color: yellow;
  transition-delay: 0.75s;
}

.color--black {
  background-color: black;
  transition-delay: 1s;
}
