#App {
  /* min-height: 200vh; */
  /* background-color: #000; */
  /* z-index: -10000; */
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #000, #ffffff);
}

.btn-ne.top{
  position: absolute;
  top: 5%;
  right: 5%;
}
.btn-ne.bottom{
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .btn-ne.bottom{
    transform: scale(0.7) translateX(-70%);
    bottom: 13%;
    left: 50%;
  }
}

.btn-ne {
  display: inline-block;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 10px;
  /* margin: 40px 0; */
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  font-family: nasa;
  z-index: 10;
  /* margin-bottom: 87px; */
}

.btn-ne:hover {
  background: #fff;
  color: #000;
  box-shadow: 0 0 5px #fff, 0 0 25px #fff, 0 0 50px #fff,
    0 0 200px #fff;
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, #0005); */
}
.btn-ne span {
  position: absolute;
  display: block;
}
.btn-ne span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #fff);
  animation: animate1 1s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
.btn-ne span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #fff);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
.btn-ne span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #fff);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.btn-ne span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #fff);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}