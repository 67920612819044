@font-face {
    src: url(rale.ttf);
    font-family: 'rale';
}

@font-face {
    font-family: 'groupe';
    src: url(groupe.otf);
}

.text-holder {
    overflow: hidden;
    color: white;
    padding: 0 10px;
    border-left: 10px solid #657fc8;
    animation: 0.4s backwards linear fade-in;
}

.title {
    animation: 1s 0.4s backwards slide-in;
    margin-bottom: 20px;
    font-family: groupe;
}

.paragraph {
    animation: 1s 1s backwards slide-in;
    font-family: rale;
    kerning: 2px;
}

@keyframes slide-in {
    from {
        transform: translate(-105%)
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

.title {
    font-size: 2.5rem;
    margin: 0;
}

.paragraph {
    font-size: 1.2rem;
    margin: 0;
    text-align: justify;
}

.glass {
    display: flex;
    justify-content: center;
    background: rgba(100, 100, 200, 0.1);
    flex-direction: column;
    width: 50%;
    padding: 60px;
    border-radius: 20px;
    backdrop-filter: blur(7px);
    box-shadow: inset 0px 0px 32px 0 rgba(255, 255, 255, 0.18);
    animation: 0.4s backwards linear fade-in;
}

@media screen and (max-width: 768px) {
    .glass {
        display: flex;
        justify-content: center;
        background: rgba(100, 100, 200, 0.1);
        flex-direction: column;
        width: 50%;
        padding: 50px 30px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        box-shadow: inset 0px 0px 32px 0 rgba(255, 255, 255, 0.18);
        animation: 0.4s backwards linear fade-in;
    }

    .paragraph {
        font-size: 12px;
    }

    .title {
        font-size: 25px;
    }

    .text-holder {
        border-left: 5px solid #657fc8;

    }
}