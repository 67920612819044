.socials-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    position: fixed;
    bottom: 25%;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    animation: fadein 2s 1s forwards;
}

.socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    color: white;
    font-size: 50px;
    padding: 20px;
    border-radius: 50%;
    margin: 30px;
    box-shadow: 0 0 20px #038bff, inset 0 0 30px rgba(60, 105, 138, 0.85);
}

.socials:hover {
    box-shadow: 0 0 10px #038bff, inset 0 0 10px rgba(60, 105, 138, 0.85), 0 0 10px #038bff;
    transform: scale(1.1);
}
@media screen and (max-width: 768px) {
    .socials-container {
        bottom: 28%;
    }
    .socials{
        font-size: 35px;
        padding: 15px;
        margin: 20px;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}    